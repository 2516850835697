.mi-10k::before {
    content: '\e951';
}

.mi-10mp::before {
    content: '\e952';
}

.mi-11mp::before {
    content: '\e953';
}

.mi-12mp::before {
    content: '\e954';
}

.mi-13mp::before {
    content: '\e955';
}

.mi-14mp::before {
    content: '\e956';
}

.mi-15mp::before {
    content: '\e957';
}

.mi-16mp::before {
    content: '\e958';
}

.mi-17mp::before {
    content: '\e959';
}

.mi-18mp::before {
    content: '\e95a';
}

.mi-19mp::before {
    content: '\e95b';
}

.mi-1k::before {
    content: '\e95c';
}

.mi-1k-plus::before {
    content: '\e95d';
}

.mi-20mp::before {
    content: '\e95e';
}

.mi-21mp::before {
    content: '\e95f';
}

.mi-22mp::before {
    content: '\e960';
}

.mi-23mp::before {
    content: '\e961';
}

.mi-24mp::before {
    content: '\e962';
}

.mi-2k::before {
    content: '\e963';
}

.mi-2k-plus::before {
    content: '\e964';
}

.mi-2mp::before {
    content: '\e965';
}

.mi-360::before {
    content: '\e577';
}

.mi-3d-rotation::before {
    content: '\e84d';
}

.mi-3k::before {
    content: '\e966';
}

.mi-3k-plus::before {
    content: '\e967';
}

.mi-3mp::before {
    content: '\e968';
}

.mi-4k::before {
    content: '\e072';
}

.mi-4k-plus::before {
    content: '\e969';
}

.mi-4mp::before {
    content: '\e96a';
}

.mi-5k::before {
    content: '\e96b';
}

.mi-5k-plus::before {
    content: '\e96c';
}

.mi-5mp::before {
    content: '\e96d';
}

.mi-6k::before {
    content: '\e96e';
}

.mi-6k-plus::before {
    content: '\e96f';
}

.mi-6mp::before {
    content: '\e970';
}

.mi-7k::before {
    content: '\e971';
}

.mi-7k-plus::before {
    content: '\e972';
}

.mi-7mp::before {
    content: '\e973';
}

.mi-8k::before {
    content: '\e974';
}

.mi-8k-plus::before {
    content: '\e975';
}

.mi-8mp::before {
    content: '\e976';
}

.mi-9k::before {
    content: '\e977';
}

.mi-9k-plus::before {
    content: '\e978';
}

.mi-9mp::before {
    content: '\e979';
}

.mi-ac-unit::before {
    content: '\eb3b';
}

.mi-access-alarm::before {
    content: '\e190';
}

.mi-access-alarms::before {
    content: '\e191';
}

.mi-access-time::before {
    content: '\e192';
}

.mi-accessibility::before {
    content: '\e84e';
}

.mi-accessibility-new::before {
    content: '\e92c';
}

.mi-accessible::before {
    content: '\e914';
}

.mi-accessible-forward::before {
    content: '\e934';
}

.mi-account-balance::before {
    content: '\e84f';
}

.mi-account-balance-wallet::before {
    content: '\e850';
}

.mi-account-box::before {
    content: '\e851';
}

.mi-account-circle::before {
    content: '\e853';
}

.mi-account-tree::before {
    content: '\e97a';
}

.mi-adb::before {
    content: '\e60e';
}

.mi-add::before {
    content: '\e145';
}

.mi-add-a-photo::before {
    content: '\e439';
}

.mi-add-alarm::before {
    content: '\e193';
}

.mi-add-alert::before {
    content: '\e003';
}

.mi-add-box::before {
    content: '\e146';
}

.mi-add-call::before {
    content: '\e0e8';
}

.mi-add-chart::before {
    content: '\e97b';
}

.mi-add-circle::before {
    content: '\e147';
}

.mi-add-circle-outline::before {
    content: '\e148';
}

.mi-add-comment::before {
    content: '\e266';
}

.mi-add-ic-call::before {
    content: '\e97c';
}

.mi-add-link::before {
    content: '\e178';
}

.mi-add-location::before {
    content: '\e567';
}

.mi-add-moderator::before {
    content: '\e97d';
}

.mi-add-photo-alternate::before {
    content: '\e43e';
}

.mi-add-shopping-cart::before {
    content: '\e854';
}

.mi-add-to-home-screen::before {
    content: '\e1fe';
}

.mi-add-to-photos::before {
    content: '\e39d';
}

.mi-add-to-queue::before {
    content: '\e05c';
}

.mi-adjust::before {
    content: '\e39e';
}

.mi-airline-seat-flat::before {
    content: '\e630';
}

.mi-airline-seat-flat-angled::before {
    content: '\e631';
}

.mi-airline-seat-individual-suite::before {
    content: '\e632';
}

.mi-airline-seat-legroom-extra::before {
    content: '\e633';
}

.mi-airline-seat-legroom-normal::before {
    content: '\e634';
}

.mi-airline-seat-legroom-reduced::before {
    content: '\e635';
}

.mi-airline-seat-recline-extra::before {
    content: '\e636';
}

.mi-airline-seat-recline-normal::before {
    content: '\e637';
}

.mi-airplanemode-active::before {
    content: '\e195';
}

.mi-airplanemode-inactive::before {
    content: '\e194';
}

.mi-airplanemode-off::before {
    content: '\e194';
}

.mi-airplanemode-on::before {
    content: '\e195';
}

.mi-airplay::before {
    content: '\e055';
}

.mi-airport-shuttle::before {
    content: '\eb3c';
}

.mi-alarm::before {
    content: '\e855';
}

.mi-alarm-add::before {
    content: '\e856';
}

.mi-alarm-off::before {
    content: '\e857';
}

.mi-alarm-on::before {
    content: '\e858';
}

.mi-album::before {
    content: '\e019';
}

.mi-all-inbox::before {
    content: '\e97f';
}

.mi-all-inclusive::before {
    content: '\eb3d';
}

.mi-all-out::before {
    content: '\e90b';
}

.mi-alternate-email::before {
    content: '\e0e6';
}

.mi-amp-stories::before {
    content: '\ea13';
}

.mi-android::before {
    content: '\e859';
}

.mi-announcement::before {
    content: '\e85a';
}

.mi-apartment::before {
    content: '\ea40';
}

.mi-approval::before {
    content: '\e982';
}

.mi-apps::before {
    content: '\e5c3';
}

.mi-archive::before {
    content: '\e149';
}

.mi-arrow-back::before {
    content: '\e5c4';
}

.mi-arrow-back-ios::before {
    content: '\e5e0';
}

.mi-arrow-downward::before {
    content: '\e5db';
}

.mi-arrow-drop-down::before {
    content: '\e5c5';
}

.mi-arrow-drop-down-circle::before {
    content: '\e5c6';
}

.mi-arrow-drop-up::before {
    content: '\e5c7';
}

.mi-arrow-forward::before {
    content: '\e5c8';
}

.mi-arrow-forward-ios::before {
    content: '\e5e1';
}

.mi-arrow-left::before {
    content: '\e5de';
}

.mi-arrow-right::before {
    content: '\e5df';
}

.mi-arrow-right-alt::before {
    content: '\e941';
}

.mi-arrow-upward::before {
    content: '\e5d8';
}

.mi-art-track::before {
    content: '\e060';
}

.mi-aspect-ratio::before {
    content: '\e85b';
}

.mi-assessment::before {
    content: '\e85c';
}

.mi-assignment::before {
    content: '\e85d';
}

.mi-assignment-ind::before {
    content: '\e85e';
}

.mi-assignment-late::before {
    content: '\e85f';
}

.mi-assignment-return::before {
    content: '\e860';
}

.mi-assignment-returned::before {
    content: '\e861';
}

.mi-assignment-turned-in::before {
    content: '\e862';
}

.mi-assistant::before {
    content: '\e39f';
}

.mi-assistant-direction::before {
    content: '\e988';
}

.mi-assistant-navigation::before {
    content: '\e989';
}

.mi-assistant-photo::before {
    content: '\e3a0';
}

.mi-atm::before {
    content: '\e573';
}

.mi-attach-file::before {
    content: '\e226';
}

.mi-attach-money::before {
    content: '\e227';
}

.mi-attachment::before {
    content: '\e2bc';
}

.mi-attractions::before {
    content: '\ea52';
}

.mi-audiotrack::before {
    content: '\e3a1';
}

.mi-autorenew::before {
    content: '\e863';
}

.mi-av-timer::before {
    content: '\e01b';
}

.mi-backspace::before {
    content: '\e14a';
}

.mi-backup::before {
    content: '\e864';
}

.mi-badge::before {
    content: '\ea67';
}

.mi-bakery-dining::before {
    content: '\ea53';
}

.mi-ballot::before {
    content: '\e172';
}

.mi-bar-chart::before {
    content: '\e26b';
}

.mi-bathtub::before {
    content: '\ea41';
}

.mi-battery-alert::before {
    content: '\e19c';
}

.mi-battery-charging-full::before {
    content: '\e1a3';
}

.mi-battery-full::before {
    content: '\e1a4';
}

.mi-battery-std::before {
    content: '\e1a5';
}

.mi-battery-unknown::before {
    content: '\e1a6';
}

.mi-beach-access::before {
    content: '\eb3e';
}

.mi-beenhere::before {
    content: '\e52d';
}

.mi-block::before {
    content: '\e14b';
}

.mi-bluetooth::before {
    content: '\e1a7';
}

.mi-bluetooth-audio::before {
    content: '\e60f';
}

.mi-bluetooth-connected::before {
    content: '\e1a8';
}

.mi-bluetooth-disabled::before {
    content: '\e1a9';
}

.mi-bluetooth-searching::before {
    content: '\e1aa';
}

.mi-blur-circular::before {
    content: '\e3a2';
}

.mi-blur-linear::before {
    content: '\e3a3';
}

.mi-blur-off::before {
    content: '\e3a4';
}

.mi-blur-on::before {
    content: '\e3a5';
}

.mi-bolt::before {
    content: '\ea0b';
}

.mi-book::before {
    content: '\e865';
}

.mi-bookmark::before {
    content: '\e866';
}

.mi-bookmark-border::before {
    content: '\e867';
}

.mi-bookmark-outline::before {
    content: '\e867';
}

.mi-bookmarks::before {
    content: '\e98b';
}

.mi-border-all::before {
    content: '\e228';
}

.mi-border-bottom::before {
    content: '\e229';
}

.mi-border-clear::before {
    content: '\e22a';
}

.mi-border-color::before {
    content: '\e22b';
}

.mi-border-horizontal::before {
    content: '\e22c';
}

.mi-border-inner::before {
    content: '\e22d';
}

.mi-border-left::before {
    content: '\e22e';
}

.mi-border-outer::before {
    content: '\e22f';
}

.mi-border-right::before {
    content: '\e230';
}

.mi-border-style::before {
    content: '\e231';
}

.mi-border-top::before {
    content: '\e232';
}

.mi-border-vertical::before {
    content: '\e233';
}

.mi-branding-watermark::before {
    content: '\e06b';
}

.mi-breakfast-dining::before {
    content: '\ea54';
}

.mi-brightness-1::before {
    content: '\e3a6';
}

.mi-brightness-2::before {
    content: '\e3a7';
}

.mi-brightness-3::before {
    content: '\e3a8';
}

.mi-brightness-4::before {
    content: '\e3a9';
}

.mi-brightness-5::before {
    content: '\e3aa';
}

.mi-brightness-6::before {
    content: '\e3ab';
}

.mi-brightness-7::before {
    content: '\e3ac';
}

.mi-brightness-auto::before {
    content: '\e1ab';
}

.mi-brightness-high::before {
    content: '\e1ac';
}

.mi-brightness-low::before {
    content: '\e1ad';
}

.mi-brightness-medium::before {
    content: '\e1ae';
}

.mi-broken-image::before {
    content: '\e3ad';
}

.mi-brunch-dining::before {
    content: '\ea73';
}

.mi-brush::before {
    content: '\e3ae';
}

.mi-bubble-chart::before {
    content: '\e6dd';
}

.mi-bug-report::before {
    content: '\e868';
}

.mi-build::before {
    content: '\e869';
}

.mi-burst-mode::before {
    content: '\e43c';
}

.mi-bus-alert::before {
    content: '\e98f';
}

.mi-business::before {
    content: '\e0af';
}

.mi-business-center::before {
    content: '\eb3f';
}

.mi-cached::before {
    content: '\e86a';
}

.mi-cake::before {
    content: '\e7e9';
}

.mi-calendar-today::before {
    content: '\e935';
}

.mi-calendar-view-day::before {
    content: '\e936';
}

.mi-call::before {
    content: '\e0b0';
}

.mi-call-end::before {
    content: '\e0b1';
}

.mi-call-made::before {
    content: '\e0b2';
}

.mi-call-merge::before {
    content: '\e0b3';
}

.mi-call-missed::before {
    content: '\e0b4';
}

.mi-call-missed-outgoing::before {
    content: '\e0e4';
}

.mi-call-received::before {
    content: '\e0b5';
}

.mi-call-split::before {
    content: '\e0b6';
}

.mi-call-to-action::before {
    content: '\e06c';
}

.mi-camera::before {
    content: '\e3af';
}

.mi-camera-alt::before {
    content: '\e3b0';
}

.mi-camera-enhance::before {
    content: '\e8fc';
}

.mi-camera-front::before {
    content: '\e3b1';
}

.mi-camera-rear::before {
    content: '\e3b2';
}

.mi-camera-roll::before {
    content: '\e3b3';
}

.mi-cancel::before {
    content: '\e5c9';
}

.mi-cancel-presentation::before {
    content: '\e0e9';
}

.mi-cancel-schedule-send::before {
    content: '\ea39';
}

.mi-car-rental::before {
    content: '\ea55';
}

.mi-car-repair::before {
    content: '\ea56';
}

.mi-card-giftcard::before {
    content: '\e8f6';
}

.mi-card-membership::before {
    content: '\e8f7';
}

.mi-card-travel::before {
    content: '\e8f8';
}

.mi-cases::before {
    content: '\e992';
}

.mi-casino::before {
    content: '\eb40';
}

.mi-cast::before {
    content: '\e307';
}

.mi-cast-connected::before {
    content: '\e308';
}

.mi-category::before {
    content: '\e574';
}

.mi-celebration::before {
    content: '\ea65';
}

.mi-cell-wifi::before {
    content: '\e0ec';
}

.mi-center-focus-strong::before {
    content: '\e3b4';
}

.mi-center-focus-weak::before {
    content: '\e3b5';
}

.mi-change-history::before {
    content: '\e86b';
}

.mi-chat::before {
    content: '\e0b7';
}

.mi-chat-bubble::before {
    content: '\e0ca';
}

.mi-chat-bubble-outline::before {
    content: '\e0cb';
}

.mi-check::before {
    content: '\e5ca';
}

.mi-check-box::before {
    content: '\e834';
}

.mi-check-box-outline-blank::before {
    content: '\e835';
}

.mi-check-circle::before {
    content: '\e86c';
}

.mi-check-circle-outline::before {
    content: '\e92d';
}

.mi-chevron-left::before {
    content: '\e5cb';
}

.mi-chevron-right::before {
    content: '\e5cc';
}

.mi-child-care::before {
    content: '\eb41';
}

.mi-child-friendly::before {
    content: '\eb42';
}

.mi-chrome-reader-mode::before {
    content: '\e86d';
}

.mi-circle-notifications::before {
    content: '\e994';
}

.mi-class::before {
    content: '\e86e';
}

.mi-clear::before {
    content: '\e14c';
}

.mi-clear-all::before {
    content: '\e0b8';
}

.mi-close::before {
    content: '\e5cd';
}

.mi-closed-caption::before {
    content: '\e01c';
}

.mi-closed-caption-off::before {
    content: '\e996';
}

.mi-cloud::before {
    content: '\e2bd';
}

.mi-cloud-circle::before {
    content: '\e2be';
}

.mi-cloud-done::before {
    content: '\e2bf';
}

.mi-cloud-download::before {
    content: '\e2c0';
}

.mi-cloud-off::before {
    content: '\e2c1';
}

.mi-cloud-queue::before {
    content: '\e2c2';
}

.mi-cloud-upload::before {
    content: '\e2c3';
}

.mi-code::before {
    content: '\e86f';
}

.mi-collections::before {
    content: '\e3b6';
}

.mi-collections-bookmark::before {
    content: '\e431';
}

.mi-color-lens::before {
    content: '\e3b7';
}

.mi-colorize::before {
    content: '\e3b8';
}

.mi-comment::before {
    content: '\e0b9';
}

.mi-commute::before {
    content: '\e940';
}

.mi-compare::before {
    content: '\e3b9';
}

.mi-compare-arrows::before {
    content: '\e915';
}

.mi-compass-calibration::before {
    content: '\e57c';
}

.mi-compress::before {
    content: '\e94d';
}

.mi-computer::before {
    content: '\e30a';
}

.mi-confirmation-num::before {
    content: '\e638';
}

.mi-confirmation-number::before {
    content: '\e638';
}

.mi-connected-tv::before {
    content: '\e998';
}

.mi-contact-mail::before {
    content: '\e0d0';
}

.mi-contact-phone::before {
    content: '\e0cf';
}

.mi-contact-support::before {
    content: '\e94c';
}

.mi-contactless::before {
    content: '\ea71';
}

.mi-contacts::before {
    content: '\e0ba';
}

.mi-content-copy::before {
    content: '\e14d';
}

.mi-content-cut::before {
    content: '\e14e';
}

.mi-content-paste::before {
    content: '\e14f';
}

.mi-control-camera::before {
    content: '\e074';
}

.mi-control-point::before {
    content: '\e3ba';
}

.mi-control-point-duplicate::before {
    content: '\e3bb';
}

.mi-copyright::before {
    content: '\e90c';
}

.mi-create::before {
    content: '\e150';
}

.mi-create-new-folder::before {
    content: '\e2cc';
}

.mi-credit-card::before {
    content: '\e870';
}

.mi-crop::before {
    content: '\e3be';
}

.mi-crop-16-9::before {
    content: '\e3bc';
}

.mi-crop-3-2::before {
    content: '\e3bd';
}

.mi-crop-5-4::before {
    content: '\e3bf';
}

.mi-crop-7-5::before {
    content: '\e3c0';
}

.mi-crop-din::before {
    content: '\e3c1';
}

.mi-crop-free::before {
    content: '\e3c2';
}

.mi-crop-landscape::before {
    content: '\e3c3';
}

.mi-crop-original::before {
    content: '\e3c4';
}

.mi-crop-portrait::before {
    content: '\e3c5';
}

.mi-crop-rotate::before {
    content: '\e437';
}

.mi-crop-square::before {
    content: '\e3c6';
}

.mi-dangerous::before {
    content: '\e99a';
}

.mi-dashboard::before {
    content: '\e871';
}

.mi-dashboard-customize::before {
    content: '\e99b';
}

.mi-data-usage::before {
    content: '\e1af';
}

.mi-date-range::before {
    content: '\e916';
}

.mi-deck::before {
    content: '\ea42';
}

.mi-dehaze::before {
    content: '\e3c7';
}

.mi-delete::before {
    content: '\e872';
}

.mi-delete-forever::before {
    content: '\e92b';
}

.mi-delete-outline::before {
    content: '\e92e';
}

.mi-delete-sweep::before {
    content: '\e16c';
}

.mi-delivery-dining::before {
    content: '\ea72';
}

.mi-departure-board::before {
    content: '\e576';
}

.mi-description::before {
    content: '\e873';
}

.mi-desktop-access-disabled::before {
    content: '\e99d';
}

.mi-desktop-mac::before {
    content: '\e30b';
}

.mi-desktop-windows::before {
    content: '\e30c';
}

.mi-details::before {
    content: '\e3c8';
}

.mi-developer-board::before {
    content: '\e30d';
}

.mi-developer-mode::before {
    content: '\e1b0';
}

.mi-device-hub::before {
    content: '\e335';
}

.mi-device-thermostat::before {
    content: '\e1ff';
}

.mi-device-unknown::before {
    content: '\e339';
}

.mi-devices::before {
    content: '\e1b1';
}

.mi-devices-other::before {
    content: '\e337';
}

.mi-dialer-sip::before {
    content: '\e0bb';
}

.mi-dialpad::before {
    content: '\e0bc';
}

.mi-dinner-dining::before {
    content: '\ea57';
}

.mi-directions::before {
    content: '\e52e';
}

.mi-directions-bike::before {
    content: '\e52f';
}

.mi-directions-boat::before {
    content: '\e532';
}

.mi-directions-bus::before {
    content: '\e530';
}

.mi-directions-car::before {
    content: '\e531';
}

.mi-directions-ferry::before {
    content: '\e532';
}

.mi-directions-railway::before {
    content: '\e534';
}

.mi-directions-run::before {
    content: '\e566';
}

.mi-directions-subway::before {
    content: '\e533';
}

.mi-directions-train::before {
    content: '\e534';
}

.mi-directions-transit::before {
    content: '\e535';
}

.mi-directions-walk::before {
    content: '\e536';
}

.mi-disc-full::before {
    content: '\e610';
}

.mi-dnd-forwardslash::before {
    content: '\e611';
}

.mi-dns::before {
    content: '\e875';
}

.mi-do-not-disturb::before {
    content: '\e612';
}

.mi-do-not-disturb-alt::before {
    content: '\e611';
}

.mi-do-not-disturb-off::before {
    content: '\e643';
}

.mi-do-not-disturb-on::before {
    content: '\e644';
}

.mi-dock::before {
    content: '\e30e';
}

.mi-domain::before {
    content: '\e7ee';
}

.mi-domain-disabled::before {
    content: '\e0ef';
}

.mi-done::before {
    content: '\e876';
}

.mi-done-all::before {
    content: '\e877';
}

.mi-done-outline::before {
    content: '\e92f';
}

.mi-donut-large::before {
    content: '\e917';
}

.mi-donut-small::before {
    content: '\e918';
}

.mi-double-arrow::before {
    content: '\ea50';
}

.mi-drafts::before {
    content: '\e151';
}

.mi-drag-handle::before {
    content: '\e25d';
}

.mi-drag-indicator::before {
    content: '\e945';
}

.mi-drive-eta::before {
    content: '\e613';
}

.mi-drive-file-move-outline::before {
    content: '\e9a1';
}

.mi-drive-file-rename-outline::before {
    content: '\e9a2';
}

.mi-drive-folder-upload::before {
    content: '\e9a3';
}

.mi-dry-cleaning::before {
    content: '\ea58';
}

.mi-duo::before {
    content: '\e9a5';
}

.mi-dvr::before {
    content: '\e1b2';
}

.mi-dynamic-feed::before {
    content: '\ea14';
}

.mi-eco::before {
    content: '\ea35';
}

.mi-edit::before {
    content: '\e3c9';
}

.mi-edit-attributes::before {
    content: '\e578';
}

.mi-edit-location::before {
    content: '\e568';
}

.mi-edit-off::before {
    content: '\e950';
}

.mi-eject::before {
    content: '\e8fb';
}

.mi-email::before {
    content: '\e0be';
}

.mi-emoji-emotions::before {
    content: '\ea22';
}

.mi-emoji-events::before {
    content: '\ea23';
}

.mi-emoji-flags::before {
    content: '\ea1a';
}

.mi-emoji-food-beverage::before {
    content: '\ea1b';
}

.mi-emoji-nature::before {
    content: '\ea1c';
}

.mi-emoji-objects::before {
    content: '\ea24';
}

.mi-emoji-people::before {
    content: '\ea1d';
}

.mi-emoji-symbols::before {
    content: '\ea1e';
}

.mi-emoji-transportation::before {
    content: '\ea1f';
}

.mi-enhance-photo-translate::before {
    content: '\e8fc';
}

.mi-enhanced-encryption::before {
    content: '\e63f';
}

.mi-equalizer::before {
    content: '\e01d';
}

.mi-error::before {
    content: '\e000';
}

.mi-error-outline::before {
    content: '\e001';
}

.mi-euro::before {
    content: '\ea15';
}

.mi-euro-symbol::before {
    content: '\e926';
}

.mi-ev-station::before {
    content: '\e56d';
}

.mi-event::before {
    content: '\e878';
}

.mi-event-available::before {
    content: '\e614';
}

.mi-event-busy::before {
    content: '\e615';
}

.mi-event-note::before {
    content: '\e616';
}

.mi-event-seat::before {
    content: '\e903';
}

.mi-exit-to-app::before {
    content: '\e879';
}

.mi-expand::before {
    content: '\e94f';
}

.mi-expand-less::before {
    content: '\e5ce';
}

.mi-expand-more::before {
    content: '\e5cf';
}

.mi-explicit::before {
    content: '\e01e';
}

.mi-explore::before {
    content: '\e87a';
}

.mi-explore-off::before {
    content: '\e9a8';
}

.mi-exposure::before {
    content: '\e3ca';
}

.mi-exposure-minus-1::before {
    content: '\e3cb';
}

.mi-exposure-minus-2::before {
    content: '\e3cc';
}

.mi-exposure-neg-1::before {
    content: '\e3cb';
}

.mi-exposure-neg-2::before {
    content: '\e3cc';
}

.mi-exposure-plus-1::before {
    content: '\e3cd';
}

.mi-exposure-plus-2::before {
    content: '\e3ce';
}

.mi-exposure-zero::before {
    content: '\e3cf';
}

.mi-extension::before {
    content: '\e87b';
}

.mi-face::before {
    content: '\e87c';
}

.mi-fast-forward::before {
    content: '\e01f';
}

.mi-fast-rewind::before {
    content: '\e020';
}

.mi-fastfood::before {
    content: '\e57a';
}

.mi-favorite::before {
    content: '\e87d';
}

.mi-favorite-border::before {
    content: '\e87e';
}

.mi-favorite-outline::before {
    content: '\e87e';
}

.mi-featured-play-list::before {
    content: '\e06d';
}

.mi-featured-video::before {
    content: '\e06e';
}

.mi-feedback::before {
    content: '\e87f';
}

.mi-festival::before {
    content: '\ea68';
}

.mi-fiber-dvr::before {
    content: '\e05d';
}

.mi-fiber-manual-record::before {
    content: '\e061';
}

.mi-fiber-new::before {
    content: '\e05e';
}

.mi-fiber-pin::before {
    content: '\e06a';
}

.mi-fiber-smart-record::before {
    content: '\e062';
}

.mi-file-copy::before {
    content: '\e173';
}

.mi-file-download::before {
    content: '\e2c4';
}

.mi-file-download-done::before {
    content: '\e9aa';
}

.mi-file-present::before {
    content: '\ea0e';
}

.mi-file-upload::before {
    content: '\e2c6';
}

.mi-filter::before {
    content: '\e3d3';
}

.mi-filter-1::before {
    content: '\e3d0';
}

.mi-filter-2::before {
    content: '\e3d1';
}

.mi-filter-3::before {
    content: '\e3d2';
}

.mi-filter-4::before {
    content: '\e3d4';
}

.mi-filter-5::before {
    content: '\e3d5';
}

.mi-filter-6::before {
    content: '\e3d6';
}

.mi-filter-7::before {
    content: '\e3d7';
}

.mi-filter-8::before {
    content: '\e3d8';
}

.mi-filter-9::before {
    content: '\e3d9';
}

.mi-filter-9-plus::before {
    content: '\e3da';
}

.mi-filter-b-and-w::before {
    content: '\e3db';
}

.mi-filter-center-focus::before {
    content: '\e3dc';
}

.mi-filter-drama::before {
    content: '\e3dd';
}

.mi-filter-frames::before {
    content: '\e3de';
}

.mi-filter-hdr::before {
    content: '\e3df';
}

.mi-filter-list::before {
    content: '\e152';
}

.mi-filter-list-alt::before {
    content: '\e94e';
}

.mi-filter-none::before {
    content: '\e3e0';
}

.mi-filter-tilt-shift::before {
    content: '\e3e2';
}

.mi-filter-vintage::before {
    content: '\e3e3';
}

.mi-find-in-page::before {
    content: '\e880';
}

.mi-find-replace::before {
    content: '\e881';
}

.mi-fingerprint::before {
    content: '\e90d';
}

.mi-fireplace::before {
    content: '\ea43';
}

.mi-first-page::before {
    content: '\e5dc';
}

.mi-fit-screen::before {
    content: '\ea10';
}

.mi-fitness-center::before {
    content: '\eb43';
}

.mi-flag::before {
    content: '\e153';
}

.mi-flare::before {
    content: '\e3e4';
}

.mi-flash-auto::before {
    content: '\e3e5';
}

.mi-flash-off::before {
    content: '\e3e6';
}

.mi-flash-on::before {
    content: '\e3e7';
}

.mi-flight::before {
    content: '\e539';
}

.mi-flight-land::before {
    content: '\e904';
}

.mi-flight-takeoff::before {
    content: '\e905';
}

.mi-flip::before {
    content: '\e3e8';
}

.mi-flip-camera-android::before {
    content: '\ea37';
}

.mi-flip-camera-ios::before {
    content: '\ea38';
}

.mi-flip-to-back::before {
    content: '\e882';
}

.mi-flip-to-front::before {
    content: '\e883';
}

.mi-folder::before {
    content: '\e2c7';
}

.mi-folder-open::before {
    content: '\e2c8';
}

.mi-folder-shared::before {
    content: '\e2c9';
}

.mi-folder-special::before {
    content: '\e617';
}

.mi-font-download::before {
    content: '\e167';
}

.mi-format-align-center::before {
    content: '\e234';
}

.mi-format-align-justify::before {
    content: '\e235';
}

.mi-format-align-left::before {
    content: '\e236';
}

.mi-format-align-right::before {
    content: '\e237';
}

.mi-format-bold::before {
    content: '\e238';
}

.mi-format-clear::before {
    content: '\e239';
}

.mi-format-color-fill::before {
    content: '\e23a';
}

.mi-format-color-reset::before {
    content: '\e23b';
}

.mi-format-color-text::before {
    content: '\e23c';
}

.mi-format-indent-decrease::before {
    content: '\e23d';
}

.mi-format-indent-increase::before {
    content: '\e23e';
}

.mi-format-italic::before {
    content: '\e23f';
}

.mi-format-line-spacing::before {
    content: '\e240';
}

.mi-format-list-bulleted::before {
    content: '\e241';
}

.mi-format-list-numbered::before {
    content: '\e242';
}

.mi-format-list-numbered-rtl::before {
    content: '\e267';
}

.mi-format-paint::before {
    content: '\e243';
}

.mi-format-quote::before {
    content: '\e244';
}

.mi-format-shapes::before {
    content: '\e25e';
}

.mi-format-size::before {
    content: '\e245';
}

.mi-format-strikethrough::before {
    content: '\e246';
}

.mi-format-textdirection-l-to-r::before {
    content: '\e247';
}

.mi-format-textdirection-r-to-l::before {
    content: '\e248';
}

.mi-format-underline::before {
    content: '\e249';
}

.mi-format-underlined::before {
    content: '\e249';
}

.mi-forum::before {
    content: '\e0bf';
}

.mi-forward::before {
    content: '\e154';
}

.mi-forward-10::before {
    content: '\e056';
}

.mi-forward-30::before {
    content: '\e057';
}

.mi-forward-5::before {
    content: '\e058';
}

.mi-free-breakfast::before {
    content: '\eb44';
}

.mi-fullscreen::before {
    content: '\e5d0';
}

.mi-fullscreen-exit::before {
    content: '\e5d1';
}

.mi-functions::before {
    content: '\e24a';
}

.mi-g-translate::before {
    content: '\e927';
}

.mi-gamepad::before {
    content: '\e30f';
}

.mi-games::before {
    content: '\e021';
}

.mi-gavel::before {
    content: '\e90e';
}

.mi-gesture::before {
    content: '\e155';
}

.mi-get-app::before {
    content: '\e884';
}

.mi-gif::before {
    content: '\e908';
}

.mi-goat::before {
    content: '\dbff';
}

.mi-golf-course::before {
    content: '\eb45';
}

.mi-gps-fixed::before {
    content: '\e1b3';
}

.mi-gps-not-fixed::before {
    content: '\e1b4';
}

.mi-gps-off::before {
    content: '\e1b5';
}

.mi-grade::before {
    content: '\e885';
}

.mi-gradient::before {
    content: '\e3e9';
}

.mi-grain::before {
    content: '\e3ea';
}

.mi-graphic-eq::before {
    content: '\e1b8';
}

.mi-grid-off::before {
    content: '\e3eb';
}

.mi-grid-on::before {
    content: '\e3ec';
}

.mi-grid-view::before {
    content: '\e9b0';
}

.mi-group::before {
    content: '\e7ef';
}

.mi-group-add::before {
    content: '\e7f0';
}

.mi-group-work::before {
    content: '\e886';
}

.mi-hail::before {
    content: '\e9b1';
}

.mi-hardware::before {
    content: '\ea59';
}

.mi-hd::before {
    content: '\e052';
}

.mi-hdr-off::before {
    content: '\e3ed';
}

.mi-hdr-on::before {
    content: '\e3ee';
}

.mi-hdr-strong::before {
    content: '\e3f1';
}

.mi-hdr-weak::before {
    content: '\e3f2';
}

.mi-headset::before {
    content: '\e310';
}

.mi-headset-mic::before {
    content: '\e311';
}

.mi-headset-off::before {
    content: '\e33a';
}

.mi-healing::before {
    content: '\e3f3';
}

.mi-hearing::before {
    content: '\e023';
}

.mi-height::before {
    content: '\ea16';
}

.mi-help::before {
    content: '\e887';
}

.mi-help-outline::before {
    content: '\e8fd';
}

.mi-high-quality::before {
    content: '\e024';
}

.mi-highlight::before {
    content: '\e25f';
}

.mi-highlight-off::before {
    content: '\e888';
}

.mi-highlight-remove::before {
    content: '\e888';
}

.mi-history::before {
    content: '\e889';
}

.mi-home::before {
    content: '\e88a';
}

.mi-home-filled::before {
    content: '\e9b2';
}

.mi-home-work::before {
    content: '\ea09';
}

.mi-horizontal-split::before {
    content: '\e947';
}

.mi-hot-tub::before {
    content: '\eb46';
}

.mi-hotel::before {
    content: '\e53a';
}

.mi-hourglass-empty::before {
    content: '\e88b';
}

.mi-hourglass-full::before {
    content: '\e88c';
}

.mi-house::before {
    content: '\ea44';
}

.mi-how-to-reg::before {
    content: '\e174';
}

.mi-how-to-vote::before {
    content: '\e175';
}

.mi-http::before {
    content: '\e902';
}

.mi-https::before {
    content: '\e88d';
}

.mi-icecream::before {
    content: '\ea69';
}

.mi-image::before {
    content: '\e3f4';
}

.mi-image-aspect-ratio::before {
    content: '\e3f5';
}

.mi-image-search::before {
    content: '\e43f';
}

.mi-imagesearch-roller::before {
    content: '\e9b4';
}

.mi-import-contacts::before {
    content: '\e0e0';
}

.mi-import-export::before {
    content: '\e0c3';
}

.mi-important-devices::before {
    content: '\e912';
}

.mi-inbox::before {
    content: '\e156';
}

.mi-indeterminate-check-box::before {
    content: '\e909';
}

.mi-info::before {
    content: '\e88e';
}

.mi-info-outline::before {
    content: '\e88f';
}

.mi-input::before {
    content: '\e890';
}

.mi-insert-chart::before {
    content: '\e24b';
}

.mi-insert-chart-outlined::before {
    content: '\e26a';
}

.mi-insert-comment::before {
    content: '\e24c';
}

.mi-insert-drive-file::before {
    content: '\e24d';
}

.mi-insert-emoticon::before {
    content: '\e24e';
}

.mi-insert-invitation::before {
    content: '\e24f';
}

.mi-insert-link::before {
    content: '\e250';
}

.mi-insert-photo::before {
    content: '\e251';
}

.mi-inventory::before {
    content: '\e179';
}

.mi-invert-colors::before {
    content: '\e891';
}

.mi-invert-colors-off::before {
    content: '\e0c4';
}

.mi-invert-colors-on::before {
    content: '\e891';
}

.mi-iso::before {
    content: '\e3f6';
}

.mi-keyboard::before {
    content: '\e312';
}

.mi-keyboard-arrow-down::before {
    content: '\e313';
}

.mi-keyboard-arrow-left::before {
    content: '\e314';
}

.mi-keyboard-arrow-right::before {
    content: '\e315';
}

.mi-keyboard-arrow-up::before {
    content: '\e316';
}

.mi-keyboard-backspace::before {
    content: '\e317';
}

.mi-keyboard-capslock::before {
    content: '\e318';
}

.mi-keyboard-control::before {
    content: '\e5d3';
}

.mi-keyboard-hide::before {
    content: '\e31a';
}

.mi-keyboard-return::before {
    content: '\e31b';
}

.mi-keyboard-tab::before {
    content: '\e31c';
}

.mi-keyboard-voice::before {
    content: '\e31d';
}

.mi-keyboard-back::before {
    content: 'keyboard_backspace';
}

.mi-king-bed::before {
    content: '\ea45';
}

.mi-kitchen::before {
    content: '\eb47';
}

.mi-label::before {
    content: '\e892';
}

.mi-label-important::before {
    content: '\e937';
}

.mi-label-important-outline::before {
    content: '\e948';
}

.mi-label-off::before {
    content: '\e9b6';
}

.mi-label-outline::before {
    content: '\e893';
}

.mi-landscape::before {
    content: '\e3f7';
}

.mi-language::before {
    content: '\e894';
}

.mi-laptop::before {
    content: '\e31e';
}

.mi-laptop-chromebook::before {
    content: '\e31f';
}

.mi-laptop-mac::before {
    content: '\e320';
}

.mi-laptop-windows::before {
    content: '\e321';
}

.mi-last-page::before {
    content: '\e5dd';
}

.mi-launch::before {
    content: '\e895';
}

.mi-layers::before {
    content: '\e53b';
}

.mi-layers-clear::before {
    content: '\e53c';
}

.mi-leak-add::before {
    content: '\e3f8';
}

.mi-leak-remove::before {
    content: '\e3f9';
}

.mi-lens::before {
    content: '\e3fa';
}

.mi-library-add::before {
    content: '\e02e';
}

.mi-library-add-check::before {
    content: '\e9b7';
}

.mi-library-books::before {
    content: '\e02f';
}

.mi-library-music::before {
    content: '\e030';
}

.mi-lightbulb::before {
    content: '\e0f0';
}

.mi-lightbulb-outline::before {
    content: '\e90f';
}

.mi-line-style::before {
    content: '\e919';
}

.mi-line-weight::before {
    content: '\e91a';
}

.mi-linear-scale::before {
    content: '\e260';
}

.mi-link::before {
    content: '\e157';
}

.mi-link-off::before {
    content: '\e16f';
}

.mi-linked-camera::before {
    content: '\e438';
}

.mi-liquor::before {
    content: '\ea60';
}

.mi-list::before {
    content: '\e896';
}

.mi-list-alt::before {
    content: '\e0ee';
}

.mi-live-help::before {
    content: '\e0c6';
}

.mi-live-tv::before {
    content: '\e639';
}

.mi-local-activity::before {
    content: '\e53f';
}

.mi-local-airport::before {
    content: '\e53d';
}

.mi-local-atm::before {
    content: '\e53e';
}

.mi-local-attraction::before {
    content: '\e53f';
}

.mi-local-bar::before {
    content: '\e540';
}

.mi-local-cafe::before {
    content: '\e541';
}

.mi-local-car-wash::before {
    content: '\e542';
}

.mi-local-convenience-store::before {
    content: '\e543';
}

.mi-local-dining::before {
    content: '\e556';
}

.mi-local-drink::before {
    content: '\e544';
}

.mi-local-florist::before {
    content: '\e545';
}

.mi-local-gas-station::before {
    content: '\e546';
}

.mi-local-grocery-store::before {
    content: '\e547';
}

.mi-local-hospital::before {
    content: '\e548';
}

.mi-local-hotel::before {
    content: '\e549';
}

.mi-local-laundry-service::before {
    content: '\e54a';
}

.mi-local-library::before {
    content: '\e54b';
}

.mi-local-mall::before {
    content: '\e54c';
}

.mi-local-movies::before {
    content: '\e54d';
}

.mi-local-offer::before {
    content: '\e54e';
}

.mi-local-parking::before {
    content: '\e54f';
}

.mi-local-pharmacy::before {
    content: '\e550';
}

.mi-local-phone::before {
    content: '\e551';
}

.mi-local-pizza::before {
    content: '\e552';
}

.mi-local-play::before {
    content: '\e553';
}

.mi-local-post-office::before {
    content: '\e554';
}

.mi-local-print-shop::before {
    content: '\e555';
}

.mi-local-printshop::before {
    content: '\e555';
}

.mi-local-restaurant::before {
    content: '\e556';
}

.mi-local-see::before {
    content: '\e557';
}

.mi-local-shipping::before {
    content: '\e558';
}

.mi-local-taxi::before {
    content: '\e559';
}

.mi-location-city::before {
    content: '\e7f1';
}

.mi-location-disabled::before {
    content: '\e1b6';
}

.mi-location-history::before {
    content: '\e55a';
}

.mi-location-off::before {
    content: '\e0c7';
}

.mi-location-on::before {
    content: '\e0c8';
}

.mi-location-searching::before {
    content: '\e1b7';
}

.mi-lock::before {
    content: '\e897';
}

.mi-lock-open::before {
    content: '\e898';
}

.mi-lock-outline::before {
    content: '\e899';
}

.mi-logout::before {
    content: '\e9ba';
}

.mi-looks::before {
    content: '\e3fc';
}

.mi-looks-3::before {
    content: '\e3fb';
}

.mi-looks-4::before {
    content: '\e3fd';
}

.mi-looks-5::before {
    content: '\e3fe';
}

.mi-looks-6::before {
    content: '\e3ff';
}

.mi-looks-one::before {
    content: '\e400';
}

.mi-looks-two::before {
    content: '\e401';
}

.mi-loop::before {
    content: '\e028';
}

.mi-loupe::before {
    content: '\e402';
}

.mi-low-priority::before {
    content: '\e16d';
}

.mi-loyalty::before {
    content: '\e89a';
}

.mi-lunch-dining::before {
    content: '\ea61';
}

.mi-mail::before {
    content: '\e158';
}

.mi-mail-outline::before {
    content: '\e0e1';
}

.mi-map::before {
    content: '\e55b';
}

.mi-margin::before {
    content: '\e9bb';
}

.mi-mark-as-unread::before {
    content: '\e9bc';
}

.mi-markunread::before {
    content: '\e159';
}

.mi-markunread-mailbox::before {
    content: '\e89b';
}

.mi-maximize::before {
    content: '\e930';
}

.mi-meeting-room::before {
    content: '\eb4f';
}

.mi-memory::before {
    content: '\e322';
}

.mi-menu::before {
    content: '\e5d2';
}

.mi-menu-book::before {
    content: '\ea19';
}

.mi-menu-open::before {
    content: '\e9bd';
}

.mi-merge-type::before {
    content: '\e252';
}

.mi-message::before {
    content: '\e0c9';
}

.mi-messenger::before {
    content: '\e0ca';
}

.mi-messenger-outline::before {
    content: '\e0cb';
}

.mi-mic::before {
    content: '\e029';
}

.mi-mic-none::before {
    content: '\e02a';
}

.mi-mic-off::before {
    content: '\e02b';
}

.mi-minimize::before {
    content: '\e931';
}

.mi-missed-video-call::before {
    content: '\e073';
}

.mi-mms::before {
    content: '\e618';
}

.mi-mobile-friendly::before {
    content: '\e200';
}

.mi-mobile-off::before {
    content: '\e201';
}

.mi-mobile-screen-share::before {
    content: '\e0e7';
}

.mi-mode-comment::before {
    content: '\e253';
}

.mi-mode-edit::before {
    content: '\e254';
}

.mi-monetization-on::before {
    content: '\e263';
}

.mi-money::before {
    content: '\e57d';
}

.mi-money-off::before {
    content: '\e25c';
}

.mi-monochrome-photos::before {
    content: '\e403';
}

.mi-mood::before {
    content: '\e7f2';
}

.mi-mood-bad::before {
    content: '\e7f3';
}

.mi-more::before {
    content: '\e619';
}

.mi-more-horiz::before {
    content: '\e5d3';
}

.mi-more-vert::before {
    content: '\e5d4';
}

.mi-motorcycle::before {
    content: '\e91b';
}

.mi-mouse::before {
    content: '\e323';
}

.mi-move-to-inbox::before {
    content: '\e168';
}

.mi-movie::before {
    content: '\e02c';
}

.mi-movie-creation::before {
    content: '\e404';
}

.mi-movie-filter::before {
    content: '\e43a';
}

.mi-mp::before {
    content: '\e9c3';
}

.mi-multiline-chart::before {
    content: '\e6df';
}

.mi-multitrack-audio::before {
    content: '\e1b8';
}

.mi-museum::before {
    content: '\ea36';
}

.mi-music-note::before {
    content: '\e405';
}

.mi-music-off::before {
    content: '\e440';
}

.mi-music-video::before {
    content: '\e063';
}

.mi-my-library-add::before {
    content: '\e02e';
}

.mi-my-library-books::before {
    content: '\e02f';
}

.mi-my-library-music::before {
    content: '\e030';
}

.mi-my-location::before {
    content: '\e55c';
}

.mi-nature::before {
    content: '\e406';
}

.mi-nature-people::before {
    content: '\e407';
}

.mi-navigate-before::before {
    content: '\e408';
}

.mi-navigate-next::before {
    content: '\e409';
}

.mi-navigation::before {
    content: '\e55d';
}

.mi-near-me::before {
    content: '\e569';
}

.mi-network-cell::before {
    content: '\e1b9';
}

.mi-network-check::before {
    content: '\e640';
}

.mi-network-locked::before {
    content: '\e61a';
}

.mi-network-wifi::before {
    content: '\e1ba';
}

.mi-new-releases::before {
    content: '\e031';
}

.mi-next-week::before {
    content: '\e16a';
}

.mi-nfc::before {
    content: '\e1bb';
}

.mi-nightlife::before {
    content: '\ea62';
}

.mi-nights-stay::before {
    content: '\ea46';
}

.mi-no-encryption::before {
    content: '\e641';
}

.mi-no-meeting-room::before {
    content: '\eb4e';
}

.mi-no-sim::before {
    content: '\e0cc';
}

.mi-not-interested::before {
    content: '\e033';
}

.mi-not-listed-location::before {
    content: '\e575';
}

.mi-note::before {
    content: '\e06f';
}

.mi-note-add::before {
    content: '\e89c';
}

.mi-notes::before {
    content: '\e26c';
}

.mi-notification-important::before {
    content: '\e004';
}

.mi-notifications::before {
    content: '\e7f4';
}

.mi-notifications-active::before {
    content: '\e7f7';
}

.mi-notifications-none::before {
    content: '\e7f5';
}

.mi-notifications-off::before {
    content: '\e7f6';
}

.mi-notifications-on::before {
    content: '\e7f7';
}

.mi-notifications-paused::before {
    content: '\e7f8';
}

.mi-now-wallpaper::before {
    content: '\e1bc';
}

.mi-now-widgets::before {
    content: '\e1bd';
}

.mi-offline-bolt::before {
    content: '\e932';
}

.mi-offline-pin::before {
    content: '\e90a';
}

.mi-offline-share::before {
    content: '\e9c5';
}

.mi-ondemand-video::before {
    content: '\e63a';
}

.mi-opacity::before {
    content: '\e91c';
}

.mi-open-in-browser::before {
    content: '\e89d';
}

.mi-open-in-new::before {
    content: '\e89e';
}

.mi-open-with::before {
    content: '\e89f';
}

.mi-outdoor-grill::before {
    content: '\ea47';
}

.mi-outlined-flag::before {
    content: '\e16e';
}

.mi-padding::before {
    content: '\e9c8';
}

.mi-pages::before {
    content: '\e7f9';
}

.mi-pageview::before {
    content: '\e8a0';
}

.mi-palette::before {
    content: '\e40a';
}

.mi-pan-tool::before {
    content: '\e925';
}

.mi-panorama::before {
    content: '\e40b';
}

.mi-panorama-fish-eye::before {
    content: '\e40c';
}

.mi-panorama-fisheye::before {
    content: '\e40c';
}

.mi-panorama-horizontal::before {
    content: '\e40d';
}

.mi-panorama-photosphere::before {
    content: '\e9c9';
}

.mi-panorama-photosphere-select::before {
    content: '\e9ca';
}

.mi-panorama-vertical::before {
    content: '\e40e';
}

.mi-panorama-wide-angle::before {
    content: '\e40f';
}

.mi-park::before {
    content: '\ea63';
}

.mi-party-mode::before {
    content: '\e7fa';
}

.mi-pause::before {
    content: '\e034';
}

.mi-pause-circle-filled::before {
    content: '\e035';
}

.mi-pause-circle-outline::before {
    content: '\e036';
}

.mi-pause-presentation::before {
    content: '\e0ea';
}

.mi-payment::before {
    content: '\e8a1';
}

.mi-people::before {
    content: '\e7fb';
}

.mi-people-alt::before {
    content: '\ea21';
}

.mi-people-outline::before {
    content: '\e7fc';
}

.mi-perm-camera-mic::before {
    content: '\e8a2';
}

.mi-perm-contact-cal::before {
    content: '\e8a3';
}

.mi-perm-contact-calendar::before {
    content: '\e8a3';
}

.mi-perm-data-setting::before {
    content: '\e8a4';
}

.mi-perm-device-info::before {
    content: '\e8a5';
}

.mi-perm-device-information::before {
    content: '\e8a5';
}

.mi-perm-identity::before {
    content: '\e8a6';
}

.mi-perm-media::before {
    content: '\e8a7';
}

.mi-perm-phone-msg::before {
    content: '\e8a8';
}

.mi-perm-scan-wifi::before {
    content: '\e8a9';
}

.mi-person::before {
    content: '\e7fd';
}

.mi-person-add::before {
    content: '\e7fe';
}

.mi-person-add-disabled::before {
    content: '\e9cb';
}

.mi-person-outline::before {
    content: '\e7ff';
}

.mi-person-pin::before {
    content: '\e55a';
}

.mi-person-pin-circle::before {
    content: '\e56a';
}

.mi-personal-video::before {
    content: '\e63b';
}

.mi-pets::before {
    content: '\e91d';
}

.mi-phone::before {
    content: '\e0cd';
}

.mi-phone-android::before {
    content: '\e324';
}

.mi-phone-bluetooth-speaker::before {
    content: '\e61b';
}

.mi-phone-callback::before {
    content: '\e649';
}

.mi-phone-disabled::before {
    content: '\e9cc';
}

.mi-phone-enabled::before {
    content: '\e9cd';
}

.mi-phone-forwarded::before {
    content: '\e61c';
}

.mi-phone-in-talk::before {
    content: '\e61d';
}

.mi-phone-iphone::before {
    content: '\e325';
}

.mi-phone-locked::before {
    content: '\e61e';
}

.mi-phone-missed::before {
    content: '\e61f';
}

.mi-phone-paused::before {
    content: '\e620';
}

.mi-phonelink::before {
    content: '\e326';
}

.mi-phonelink-erase::before {
    content: '\e0db';
}

.mi-phonelink-lock::before {
    content: '\e0dc';
}

.mi-phonelink-off::before {
    content: '\e327';
}

.mi-phonelink-ring::before {
    content: '\e0dd';
}

.mi-phonelink-setup::before {
    content: '\e0de';
}

.mi-photo::before {
    content: '\e410';
}

.mi-photo-album::before {
    content: '\e411';
}

.mi-photo-camera::before {
    content: '\e412';
}

.mi-photo-filter::before {
    content: '\e43b';
}

.mi-photo-library::before {
    content: '\e413';
}

.mi-photo-size-select-actual::before {
    content: '\e432';
}

.mi-photo-size-select-large::before {
    content: '\e433';
}

.mi-photo-size-select-small::before {
    content: '\e434';
}

.mi-picture-as-pdf::before {
    content: '\e415';
}

.mi-picture-in-picture::before {
    content: '\e8aa';
}

.mi-picture-in-picture-alt::before {
    content: '\e911';
}

.mi-pie-chart::before {
    content: '\e6c4';
}

.mi-pie-chart-outlined::before {
    content: '\e6c5';
}

.mi-pin-drop::before {
    content: '\e55e';
}

.mi-pivot-table-chart::before {
    content: '\e9ce';
}

.mi-place::before {
    content: '\e55f';
}

.mi-play-arrow::before {
    content: '\e037';
}

.mi-play-circle-fill::before {
    content: '\e038';
}

.mi-play-circle-filled::before {
    content: '\e038';
}

.mi-play-circle-outline::before {
    content: '\e039';
}

.mi-play-for-work::before {
    content: '\e906';
}

.mi-playlist-add::before {
    content: '\e03b';
}

.mi-playlist-add-check::before {
    content: '\e065';
}

.mi-playlist-play::before {
    content: '\e05f';
}

.mi-plus-one::before {
    content: '\e800';
}

.mi-policy::before {
    content: '\ea17';
}

.mi-poll::before {
    content: '\e801';
}

.mi-polymer::before {
    content: '\e8ab';
}

.mi-pool::before {
    content: '\eb48';
}

.mi-portable-wifi-off::before {
    content: '\e0ce';
}

.mi-portrait::before {
    content: '\e416';
}

.mi-post-add::before {
    content: '\ea20';
}

.mi-power::before {
    content: '\e63c';
}

.mi-power-input::before {
    content: '\e336';
}

.mi-power-off::before {
    content: '\e646';
}

.mi-power-settings-new::before {
    content: '\e8ac';
}

.mi-pregnant-woman::before {
    content: '\e91e';
}

.mi-present-to-all::before {
    content: '\e0df';
}

.mi-print::before {
    content: '\e8ad';
}

.mi-print-disabled::before {
    content: '\e9cf';
}

.mi-priority-high::before {
    content: '\e645';
}

.mi-public::before {
    content: '\e80b';
}

.mi-publish::before {
    content: '\e255';
}

.mi-query-builder::before {
    content: '\e8ae';
}

.mi-question-answer::before {
    content: '\e8af';
}

.mi-queue::before {
    content: '\e03c';
}

.mi-queue-music::before {
    content: '\e03d';
}

.mi-queue-play-next::before {
    content: '\e066';
}

.mi-quick-contacts-dialer::before {
    content: '\e0cf';
}

.mi-quick-contacts-mail::before {
    content: '\e0d0';
}

.mi-radio::before {
    content: '\e03e';
}

.mi-radio-button-checked::before {
    content: '\e837';
}

.mi-radio-button-off::before {
    content: '\e836';
}

.mi-radio-button-on::before {
    content: '\e837';
}

.mi-radio-button-unchecked::before {
    content: '\e836';
}

.mi-railway-alert::before {
    content: '\e9d1';
}

.mi-ramen-dining::before {
    content: '\ea64';
}

.mi-rate-review::before {
    content: '\e560';
}

.mi-receipt::before {
    content: '\e8b0';
}

.mi-recent-actors::before {
    content: '\e03f';
}

.mi-recommend::before {
    content: '\e9d2';
}

.mi-record-voice-over::before {
    content: '\e91f';
}

.mi-redeem::before {
    content: '\e8b1';
}

.mi-redo::before {
    content: '\e15a';
}

.mi-refresh::before {
    content: '\e5d5';
}

.mi-remove::before {
    content: '\e15b';
}

.mi-remove-circle::before {
    content: '\e15c';
}

.mi-remove-circle-outline::before {
    content: '\e15d';
}

.mi-remove-done::before {
    content: '\e9d3';
}

.mi-remove-from-queue::before {
    content: '\e067';
}

.mi-remove-moderator::before {
    content: '\e9d4';
}

.mi-remove-red-eye::before {
    content: '\e417';
}

.mi-remove-shopping-cart::before {
    content: '\e928';
}

.mi-reorder::before {
    content: '\e8fe';
}

.mi-repeat::before {
    content: '\e040';
}

.mi-repeat-on::before {
    content: '\e9d6';
}

.mi-repeat-one::before {
    content: '\e041';
}

.mi-repeat-one-on::before {
    content: '\e9d7';
}

.mi-replay::before {
    content: '\e042';
}

.mi-replay-10::before {
    content: '\e059';
}

.mi-replay-30::before {
    content: '\e05a';
}

.mi-replay-5::before {
    content: '\e05b';
}

.mi-replay-circle-filled::before {
    content: '\e9d8';
}

.mi-reply::before {
    content: '\e15e';
}

.mi-reply-all::before {
    content: '\e15f';
}

.mi-report::before {
    content: '\e160';
}

.mi-report-off::before {
    content: '\e170';
}

.mi-report-problem::before {
    content: '\e8b2';
}

.mi-reset-tv::before {
    content: '\e9d9';
}

.mi-restaurant::before {
    content: '\e56c';
}

.mi-restaurant-menu::before {
    content: '\e561';
}

.mi-restore::before {
    content: '\e8b3';
}

.mi-restore-from-trash::before {
    content: '\e938';
}

.mi-restore-page::before {
    content: '\e929';
}

.mi-ring-volume::before {
    content: '\e0d1';
}

.mi-room::before {
    content: '\e8b4';
}

.mi-room-service::before {
    content: '\eb49';
}

.mi-rotate-90-degrees-ccw::before {
    content: '\e418';
}

.mi-rotate-left::before {
    content: '\e419';
}

.mi-rotate-right::before {
    content: '\e41a';
}

.mi-rounded-corner::before {
    content: '\e920';
}

.mi-router::before {
    content: '\e328';
}

.mi-rowing::before {
    content: '\e921';
}

.mi-rss-feed::before {
    content: '\e0e5';
}

.mi-rtt::before {
    content: '\e9ad';
}

.mi-rv-hookup::before {
    content: '\e642';
}

.mi-satellite::before {
    content: '\e562';
}

.mi-save::before {
    content: '\e161';
}

.mi-save-alt::before {
    content: '\e171';
}

.mi-saved-search::before {
    content: '\ea11';
}

.mi-scanner::before {
    content: '\e329';
}

.mi-scatter-plot::before {
    content: '\e268';
}

.mi-schedule::before {
    content: '\e8b5';
}

.mi-schedule-send::before {
    content: '\ea0a';
}

.mi-school::before {
    content: '\e80c';
}

.mi-score::before {
    content: '\e269';
}

.mi-screen-lock-landscape::before {
    content: '\e1be';
}

.mi-screen-lock-portrait::before {
    content: '\e1bf';
}

.mi-screen-lock-rotation::before {
    content: '\e1c0';
}

.mi-screen-rotation::before {
    content: '\e1c1';
}

.mi-screen-share::before {
    content: '\e0e2';
}

.mi-sd::before {
    content: '\e9dd';
}

.mi-sd-card::before {
    content: '\e623';
}

.mi-sd-storage::before {
    content: '\e1c2';
}

.mi-search::before {
    content: '\e8b6';
}

.mi-security::before {
    content: '\e32a';
}

.mi-segment::before {
    content: '\e94b';
}

.mi-select-all::before {
    content: '\e162';
}

.mi-send::before {
    content: '\e163';
}

.mi-send-and-archive::before {
    content: '\ea0c';
}

.mi-sentiment-dissatisfied::before {
    content: '\e811';
}

.mi-sentiment-neutral::before {
    content: '\e812';
}

.mi-sentiment-satisfied::before {
    content: '\e813';
}

.mi-sentiment-satisfied-alt::before {
    content: '\e0ed';
}

.mi-sentiment-very-dissatisfied::before {
    content: '\e814';
}

.mi-sentiment-very-satisfied::before {
    content: '\e815';
}

.mi-settings::before {
    content: '\e8b8';
}

.mi-settings-applications::before {
    content: '\e8b9';
}

.mi-settings-backup-restore::before {
    content: '\e8ba';
}

.mi-settings-bluetooth::before {
    content: '\e8bb';
}

.mi-settings-brightness::before {
    content: '\e8bd';
}

.mi-settings-cell::before {
    content: '\e8bc';
}

.mi-settings-display::before {
    content: '\e8bd';
}

.mi-settings-ethernet::before {
    content: '\e8be';
}

.mi-settings-input-antenna::before {
    content: '\e8bf';
}

.mi-settings-input-component::before {
    content: '\e8c0';
}

.mi-settings-input-composite::before {
    content: '\e8c1';
}

.mi-settings-input-hdmi::before {
    content: '\e8c2';
}

.mi-settings-input-svideo::before {
    content: '\e8c3';
}

.mi-settings-overscan::before {
    content: '\e8c4';
}

.mi-settings-phone::before {
    content: '\e8c5';
}

.mi-settings-power::before {
    content: '\e8c6';
}

.mi-settings-remote::before {
    content: '\e8c7';
}

.mi-settings-system-daydream::before {
    content: '\e1c3';
}

.mi-settings-voice::before {
    content: '\e8c8';
}

.mi-share::before {
    content: '\e80d';
}

.mi-shield::before {
    content: '\e9e0';
}

.mi-shop::before {
    content: '\e8c9';
}

.mi-shop-two::before {
    content: '\e8ca';
}

.mi-shopping-basket::before {
    content: '\e8cb';
}

.mi-shopping-cart::before {
    content: '\e8cc';
}

.mi-short-text::before {
    content: '\e261';
}

.mi-show-chart::before {
    content: '\e6e1';
}

.mi-shuffle::before {
    content: '\e043';
}

.mi-shuffle-on::before {
    content: '\e9e1';
}

.mi-shutter-speed::before {
    content: '\e43d';
}

.mi-signal-cellular-4-bar::before {
    content: '\e1c8';
}

.mi-signal-cellular-alt::before {
    content: '\e202';
}

.mi-signal-cellular-connected-no-internet-4-bar::before {
    content: '\e1cd';
}

.mi-signal-cellular-no-sim::before {
    content: '\e1ce';
}

.mi-signal-cellular-null::before {
    content: '\e1cf';
}

.mi-signal-cellular-off::before {
    content: '\e1d0';
}

.mi-signal-wifi-4-bar::before {
    content: '\e1d8';
}

.mi-signal-wifi-4-bar-lock::before {
    content: '\e1d9';
}

.mi-signal-wifi-off::before {
    content: '\e1da';
}

.mi-sim-card::before {
    content: '\e32b';
}

.mi-sim-card-alert::before {
    content: '\e624';
}

.mi-single-bed::before {
    content: '\ea48';
}

.mi-skip-next::before {
    content: '\e044';
}

.mi-skip-previous::before {
    content: '\e045';
}

.mi-slideshow::before {
    content: '\e41b';
}

.mi-slow-motion-video::before {
    content: '\e068';
}

.mi-smartphone::before {
    content: '\e32c';
}

.mi-smoke-free::before {
    content: '\eb4a';
}

.mi-smoking-rooms::before {
    content: '\eb4b';
}

.mi-sms::before {
    content: '\e625';
}

.mi-sms-failed::before {
    content: '\e626';
}

.mi-snooze::before {
    content: '\e046';
}

.mi-sort::before {
    content: '\e164';
}

.mi-sort-by-alpha::before {
    content: '\e053';
}

.mi-spa::before {
    content: '\eb4c';
}

.mi-space-bar::before {
    content: '\e256';
}

.mi-speaker::before {
    content: '\e32d';
}

.mi-speaker-group::before {
    content: '\e32e';
}

.mi-speaker-notes::before {
    content: '\e8cd';
}

.mi-speaker-notes-off::before {
    content: '\e92a';
}

.mi-speaker-phone::before {
    content: '\e0d2';
}

.mi-speed::before {
    content: '\e9e4';
}

.mi-spellcheck::before {
    content: '\e8ce';
}

.mi-sports::before {
    content: '\ea30';
}

.mi-sports-baseball::before {
    content: '\ea51';
}

.mi-sports-basketball::before {
    content: '\ea26';
}

.mi-sports-cricket::before {
    content: '\ea27';
}

.mi-sports-esports::before {
    content: '\ea28';
}

.mi-sports-football::before {
    content: '\ea29';
}

.mi-sports-golf::before {
    content: '\ea2a';
}

.mi-sports-handball::before {
    content: '\ea33';
}

.mi-sports-hockey::before {
    content: '\ea2b';
}

.mi-sports-kabaddi::before {
    content: '\ea34';
}

.mi-sports-mma::before {
    content: '\ea2c';
}

.mi-sports-motorsports::before {
    content: '\ea2d';
}

.mi-sports-rugby::before {
    content: '\ea2e';
}

.mi-sports-soccer::before {
    content: '\ea2f';
}

.mi-sports-tennis::before {
    content: '\ea32';
}

.mi-sports-volleyball::before {
    content: '\ea31';
}

.mi-square-foot::before {
    content: '\ea49';
}

.mi-stacked-bar-chart::before {
    content: '\e9e6';
}

.mi-star::before {
    content: '\e838';
}

.mi-star-border::before {
    content: '\e83a';
}

.mi-star-half::before {
    content: '\e839';
}

.mi-star-outline::before {
    content: '\e83a';
}

.mi-stars::before {
    content: '\e8d0';
}

.mi-stay-current-landscape::before {
    content: '\e0d3';
}

.mi-stay-current-portrait::before {
    content: '\e0d4';
}

.mi-stay-primary-landscape::before {
    content: '\e0d5';
}

.mi-stay-primary-portrait::before {
    content: '\e0d6';
}

.mi-stop::before {
    content: '\e047';
}

.mi-stop-screen-share::before {
    content: '\e0e3';
}

.mi-storage::before {
    content: '\e1db';
}

.mi-store::before {
    content: '\e8d1';
}

.mi-store-mall-directory::before {
    content: '\e563';
}

.mi-storefront::before {
    content: '\ea12';
}

.mi-straighten::before {
    content: '\e41c';
}

.mi-stream::before {
    content: '\e9e9';
}

.mi-streetview::before {
    content: '\e56e';
}

.mi-strikethrough-s::before {
    content: '\e257';
}

.mi-style::before {
    content: '\e41d';
}

.mi-subdirectory-arrow-left::before {
    content: '\e5d9';
}

.mi-subdirectory-arrow-right::before {
    content: '\e5da';
}

.mi-subject::before {
    content: '\e8d2';
}

.mi-subscriptions::before {
    content: '\e064';
}

.mi-subtitles::before {
    content: '\e048';
}

.mi-subway::before {
    content: '\e56f';
}

.mi-supervised-user-circle::before {
    content: '\e939';
}

.mi-supervisor-account::before {
    content: '\e8d3';
}

.mi-surround-sound::before {
    content: '\e049';
}

.mi-swap-calls::before {
    content: '\e0d7';
}

.mi-swap-horiz::before {
    content: '\e8d4';
}

.mi-swap-horizontal-circle::before {
    content: '\e933';
}

.mi-swap-vert::before {
    content: '\e8d5';
}

.mi-swap-vert-circle::before {
    content: '\e8d6';
}

.mi-swap-vertical-circle::before {
    content: '\e8d6';
}

.mi-swipe::before {
    content: '\e9ec';
}

.mi-switch-account::before {
    content: '\e9ed';
}

.mi-switch-camera::before {
    content: '\e41e';
}

.mi-switch-video::before {
    content: '\e41f';
}

.mi-sync::before {
    content: '\e627';
}

.mi-sync-alt::before {
    content: '\ea18';
}

.mi-sync-disabled::before {
    content: '\e628';
}

.mi-sync-problem::before {
    content: '\e629';
}

.mi-system-update::before {
    content: '\e62a';
}

.mi-system-update-alt::before {
    content: '\e8d7';
}

.mi-system-update-tv::before {
    content: '\e8d7';
}

.mi-tab::before {
    content: '\e8d8';
}

.mi-tab-unselected::before {
    content: '\e8d9';
}

.mi-table-chart::before {
    content: '\e265';
}

.mi-tablet::before {
    content: '\e32f';
}

.mi-tablet-android::before {
    content: '\e330';
}

.mi-tablet-mac::before {
    content: '\e331';
}

.mi-tag::before {
    content: '\e9ef';
}

.mi-tag-faces::before {
    content: '\e420';
}

.mi-takeout-dining::before {
    content: '\ea74';
}

.mi-tap-and-play::before {
    content: '\e62b';
}

.mi-terrain::before {
    content: '\e564';
}

.mi-text-fields::before {
    content: '\e262';
}

.mi-text-format::before {
    content: '\e165';
}

.mi-text-rotate-up::before {
    content: '\e93a';
}

.mi-text-rotate-vertical::before {
    content: '\e93b';
}

.mi-text-rotation-angledown::before {
    content: '\e93c';
}

.mi-text-rotation-angleup::before {
    content: '\e93d';
}

.mi-text-rotation-down::before {
    content: '\e93e';
}

.mi-text-rotation-none::before {
    content: '\e93f';
}

.mi-textsms::before {
    content: '\e0d8';
}

.mi-texture::before {
    content: '\e421';
}

.mi-theater-comedy::before {
    content: '\ea66';
}

.mi-theaters::before {
    content: '\e8da';
}

.mi-thumb-down::before {
    content: '\e8db';
}

.mi-thumb-down-alt::before {
    content: '\e816';
}

.mi-thumb-down-off-alt::before {
    content: '\e9f2';
}

.mi-thumb-up::before {
    content: '\e8dc';
}

.mi-thumb-up-alt::before {
    content: '\e817';
}

.mi-thumb-up-off-alt::before {
    content: '\e9f3';
}

.mi-thumbs-up-down::before {
    content: '\e8dd';
}

.mi-time-to-leave::before {
    content: '\e62c';
}

.mi-timelapse::before {
    content: '\e422';
}

.mi-timeline::before {
    content: '\e922';
}

.mi-timer::before {
    content: '\e425';
}

.mi-timer-10::before {
    content: '\e423';
}

.mi-timer-3::before {
    content: '\e424';
}

.mi-timer-off::before {
    content: '\e426';
}

.mi-title::before {
    content: '\e264';
}

.mi-toc::before {
    content: '\e8de';
}

.mi-today::before {
    content: '\e8df';
}

.mi-toggle-off::before {
    content: '\e9f5';
}

.mi-toggle-on::before {
    content: '\e9f6';
}

.mi-toll::before {
    content: '\e8e0';
}

.mi-tonality::before {
    content: '\e427';
}

.mi-touch-app::before {
    content: '\e913';
}

.mi-toys::before {
    content: '\e332';
}

.mi-track-changes::before {
    content: '\e8e1';
}

.mi-traffic::before {
    content: '\e565';
}

.mi-train::before {
    content: '\e570';
}

.mi-tram::before {
    content: '\e571';
}

.mi-transfer-within-a-station::before {
    content: '\e572';
}

.mi-transform::before {
    content: '\e428';
}

.mi-transit-enterexit::before {
    content: '\e579';
}

.mi-translate::before {
    content: '\e8e2';
}

.mi-trending-down::before {
    content: '\e8e3';
}

.mi-trending-flat::before {
    content: '\e8e4';
}

.mi-trending-neutral::before {
    content: '\e8e4';
}

.mi-trending-up::before {
    content: '\e8e5';
}

.mi-trip-origin::before {
    content: '\e57b';
}

.mi-tune::before {
    content: '\e429';
}

.mi-turned-in::before {
    content: '\e8e6';
}

.mi-turned-in-not::before {
    content: '\e8e7';
}

.mi-tv::before {
    content: '\e333';
}

.mi-tv-off::before {
    content: '\e647';
}

.mi-two-wheeler::before {
    content: '\e9f9';
}

.mi-unarchive::before {
    content: '\e169';
}

.mi-undo::before {
    content: '\e166';
}

.mi-unfold-less::before {
    content: '\e5d6';
}

.mi-unfold-more::before {
    content: '\e5d7';
}

.mi-unsubscribe::before {
    content: '\e0eb';
}

.mi-update::before {
    content: '\e923';
}

.mi-upload-file::before {
    content: '\e9fc';
}

.mi-usb::before {
    content: '\e1e0';
}

.mi-verified-user::before {
    content: '\e8e8';
}

.mi-vertical-align-bottom::before {
    content: '\e258';
}

.mi-vertical-align-center::before {
    content: '\e259';
}

.mi-vertical-align-top::before {
    content: '\e25a';
}

.mi-vertical-split::before {
    content: '\e949';
}

.mi-vibration::before {
    content: '\e62d';
}

.mi-video-call::before {
    content: '\e070';
}

.mi-video-collection::before {
    content: '\e04a';
}

.mi-video-label::before {
    content: '\e071';
}

.mi-video-library::before {
    content: '\e04a';
}

.mi-videocam::before {
    content: '\e04b';
}

.mi-videocam-off::before {
    content: '\e04c';
}

.mi-videogame-asset::before {
    content: '\e338';
}

.mi-view-agenda::before {
    content: '\e8e9';
}

.mi-view-array::before {
    content: '\e8ea';
}

.mi-view-carousel::before {
    content: '\e8eb';
}

.mi-view-column::before {
    content: '\e8ec';
}

.mi-view-comfortable::before {
    content: '\e42a';
}

.mi-view-comfy::before {
    content: '\e42a';
}

.mi-view-compact::before {
    content: '\e42b';
}

.mi-view-day::before {
    content: '\e8ed';
}

.mi-view-headline::before {
    content: '\e8ee';
}

.mi-view-in-ar::before {
    content: '\e9fe';
}

.mi-view-list::before {
    content: '\e8ef';
}

.mi-view-module::before {
    content: '\e8f0';
}

.mi-view-quilt::before {
    content: '\e8f1';
}

.mi-view-stream::before {
    content: '\e8f2';
}

.mi-view-week::before {
    content: '\e8f3';
}

.mi-vignette::before {
    content: '\e435';
}

.mi-visibility::before {
    content: '\e8f4';
}

.mi-visibility-off::before {
    content: '\e8f5';
}

.mi-voice-chat::before {
    content: '\e62e';
}

.mi-voice-over-off::before {
    content: '\e94a';
}

.mi-voicemail::before {
    content: '\e0d9';
}

.mi-volume-down::before {
    content: '\e04d';
}

.mi-volume-mute::before {
    content: '\e04e';
}

.mi-volume-off::before {
    content: '\e04f';
}

.mi-volume-up::before {
    content: '\e050';
}

.mi-volunteer-activism::before {
    content: '\ea70';
}

.mi-vpn-key::before {
    content: '\e0da';
}

.mi-vpn-lock::before {
    content: '\e62f';
}

.mi-wallet-giftcard::before {
    content: '\e8f6';
}

.mi-wallet-membership::before {
    content: '\e8f7';
}

.mi-wallet-travel::before {
    content: '\e8f8';
}

.mi-wallpaper::before {
    content: '\e1bc';
}

.mi-warning::before {
    content: '\e002';
}

.mi-watch::before {
    content: '\e334';
}

.mi-watch-later::before {
    content: '\e924';
}

.mi-waterfall-chart::before {
    content: '\ea00';
}

.mi-waves::before {
    content: '\e176';
}

.mi-wb-auto::before {
    content: '\e42c';
}

.mi-wb-cloudy::before {
    content: '\e42d';
}

.mi-wb-incandescent::before {
    content: '\e42e';
}

.mi-wb-iridescent::before {
    content: '\e436';
}

.mi-wb-shade::before {
    content: '\ea01';
}

.mi-wb-sunny::before {
    content: '\e430';
}

.mi-wb-twighlight::before {
    content: '\ea02';
}

.mi-wc::before {
    content: '\e63d';
}

.mi-web::before {
    content: '\e051';
}

.mi-web-asset::before {
    content: '\e069';
}

.mi-weekend::before {
    content: '\e16b';
}

.mi-whatshot::before {
    content: '\e80e';
}

.mi-where-to-vote::before {
    content: '\e177';
}

.mi-widgets::before {
    content: '\e1bd';
}

.mi-wifi::before {
    content: '\e63e';
}

.mi-wifi-lock::before {
    content: '\e1e1';
}

.mi-wifi-off::before {
    content: '\e648';
}

.mi-wifi-tethering::before {
    content: '\e1e2';
}

.mi-work::before {
    content: '\e8f9';
}

.mi-work-off::before {
    content: '\e942';
}

.mi-work-outline::before {
    content: '\e943';
}

.mi-workspaces-filled::before {
    content: '\ea0d';
}

.mi-workspaces-outline::before {
    content: '\ea0f';
}

.mi-wrap-text::before {
    content: '\e25b';
}

.mi-youtube-searched-for::before {
    content: '\e8fa';
}

.mi-zoom-in::before {
    content: '\e8ff';
}

.mi-zoom-out::before {
    content: '\e900';
}

.mi-zoom-out-map::before {
    content: '\e56b';
}
