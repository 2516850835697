@import "~@jutro/theme/assets/sass/helpers";

.pageContainer {
    --gw-amp-landing-page-page-container-max-width: 90em;
    --gw-amp-landing-page-page-container-grid-areas: "pageTitle pageTitle" "infoAndPolicyTable quickLinksAndAdSection";
    --gw-amp-landing-page-page-container-grid-areas-tablet: "infoAndPolicyTable" "quickLinksAndAdSection";
    --gw-amp-landing-page-page-container-grid-template-row: auto auto;
    --gw-amp-landing-page-page-container-grid-template-column: 72% 25%;
    --gw-amp-landing-page-page-container-grid-template-column-tablet: 1fr;

    @include gw-breakpoint-down(tablet) {
        --gw-amp-landing-page-page-container-grid-areas: var(--gw-amp-landing-page-page-container-grid-areas-tablet);
        --gw-amp-landing-page-page-container-grid-template-column: var(--gw-amp-landing-page-page-container-grid-template-column-tablet);
    }

    grid-template-rows: var(--gw-amp-landing-page-page-container-grid-template-row);
    grid-template-columns: var(--gw-amp-landing-page-page-container-grid-template-column);
    max-width: var(--gw-amp-landing-page-page-container-max-width);
    display: grid;
    grid-gap: var(--GW-SPACING-6);
    justify-content: space-between;
    grid-template-areas: var(--gw-amp-landing-page-page-container-grid-areas);
    overflow-x: scroll;

    .icon > i {
        font-size: var(--GW-FONT-SIZE-H2);
        color: var(--GW-TEXT-COLOR-2);
    }
}

.pageTitle {
    grid-area: pageTitle;
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.tileWithAdContainer {
    --gw-amp-landing-page-tile-container-width: 100%;

    width: var(--gw-amp-landing-page-tile-container-width);
    grid-column-gap: var(--GW-SPACING-6);
}

.gwTileAndTableContainer {
    grid-area: infoAndPolicyTable;
}

.gwQuickLinksAndAdContainer {
    grid-area: quickLinksAndAdSection;
}

.gwPoliciesTableTitle {
    padding: 0;

    @include gw-breakpoint-only(phone) {
        padding-top: var(--GW-SPACING-4);
    }
}

.adSection {
    padding-top: var(--GW-SPACING-8);

    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.quickLinksContainer {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
    @include gw-breakpoint-down(tablet) {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: fit-content;
        a {
            padding: 0 var(--GW-SPACING-3);
            justify-content: center;
        }
    }
}

.tileContainer {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
    div[class*='jut__FieldLabel__left'] {
        flex: 1 2;
    }
}

.halfBannerContainer {
    --gw-amp-landing-page-tile-container-width: 200px;
    display: none;
    @include gw-breakpoint-only(phone) {
        --gw-amp-landing-page-tile-container-width: 100%;
        --gw-amp-landing-page-tile-container-border: unset;
        display: block;
    }

    width: var(--gw-amp-landing-page-tile-container-width);
}

.textAlignRight {
    text-align: right;
}

.gwTileAmount {
    --gw-amp-landing-page-tile-title-label-min-width: 100px;

    text-align: center;
    align-items: baseline;
    padding-bottom: var(--GW-SPACING-5);

    div:last-child {
        font-size: var(--GW-FONT-SIZE-H4);
        text-align: right;
    }

    .gwTileAmountLabel {
        @include gw-breakpoint-down(phone) {
            width: max-content;
        }
    }
}

.payNowMyBalance {
    --gw-amp-landing-page-my-balance-pay-now-margin-top: 6px;
    margin-top: var(--gw-amp-landing-page-my-balance-pay-now-margin-top);
    width:100%;
}

.tileWarning span {
    color: var(--GW-COLOR-ERROR);
}

.renewedPolicyIcon {
    color: var(--GW-FOCUS-COLOR);
}

.quickLink {
    --gw-amp-landing-page-quick-link-margin-bottom: 15px;
    display: flex;
    align-items: center;
    i[class*='Link'] {
        font-size: var(--GW-FONT-SIZE-H4);
    }
    @include gw-breakpoint-down(tablet) {
        background-color: var(--GW-FOCUS-COLOR);
        text-align: center;
        width: 100%;

        span {
            color: var(--GW-BACKGROUND-COLOR);
        }

        i {
            display: none;
        }
    }

    &:not(:last-child) {
        margin-bottom: var(--gw-amp-landing-page-quick-link-margin-bottom);
    }
}

.quickLinkTitle {
    --gw-amp-landing-page-quick-link-title-margin-bottom: 16px;
    margin-bottom: var(--gw-amp-landing-page-quick-link-title-margin-bottom) !important;
}

@include gw-breakpoint-down(tablet) {
    .quickLinkTitle {
        margin: auto;
    }
}

.enrollPoliciesContainer {
    --gw-amp-landing-page-enroll-policy-button-margin-top: 24px;
    margin-top: var(--gw-amp-landing-page-enroll-policy-button-margin-top);
}
.policiesTable {
    padding: 0;

    div[class*='DateRangeComponent'] {
        justify-content: center;
      }
}
.tableContainer {
    --gw-amp-table-container-padding: 24px 24px 32px 24px;
    --gw-amp-table-container-radius: 4px;
    background: var(--GW-BACKGROUND-COLOR);
    padding: var(--gw-amp-table-container-padding);
    border-radius: var(--gw-amp-table-container-radius);
    box-shadow: var(--GW-SHADOW-2);
}

.messageSegregator {
    margin-top: var(--GW-SPACING-5);
    margin-bottom: var(--GW-SPACING-5);
}
