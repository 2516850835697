@import "~@jutro/theme/assets/sass/helpers";

.printButton {
    justify-self: flex-end;
    --icon-button-padding: 0;
}

.policyDetailContainer {
    padding: var(--GW-LAYOUT-3) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
}

.policyDetailsSection:not(:last-child) {
    --gw-policyDetailsSection-margin-bottom: 4rem;
    margin-bottom: var(--gw-policyDetailsSection-margin-bottom);
    
    @include gw-breakpoint-down(phonewide) {
        overflow: scroll;
    }
}

.idCardContainer {
    display: flex;
    justify-content: flex-end;
}

.policyHeaderContainer {
    margin-bottom: var(--GW-LAYOUT-4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    i {
        position: relative;
    }
}

.policyChangeButtonContainer {
    margin-top: var(--GW-LAYOUT-6);
}

.headerContainer {
    margin-bottom: var(--GW-LAYOUT-4);
}

h3[class='subTitle'] {
    border-bottom: var(--GW-BORDER-WIDTH) solidvar(--GW-BORDER-COLOR);
    margin-bottom: var(--GW-SPACING-4);
}
.payrollReportMessage {
    border: 1px solid;
    padding: var(--GW-SPACING-6);
    margin-top: var(--GW-SPACING-6);
}