@import "~@jutro/theme/assets/sass/helpers";

.tabContent {
    display: flex;
    align-items: center;

    > i {
        padding-right: 2px;
    }
}

.billingSummaryContainer {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
    margin-top: var(--GW-LAYOUT-4);
}

.payeezyBackButton {
    margin-bottom: var(--GW-SPACING-2);
    i {
        position: relative;
        top: calc(1.25 * var(--GW-SPACING-1));
    }
}