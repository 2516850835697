@import "~@jutro/theme/assets/sass/helpers";
.grayBackground {
    background-color: var(--GW-BACKGROUND-COLOR-BODY);
    border-radius: var(--GW-BORDER-RADIUS);
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}

.totalPremiumContainer {
    div[class^='jut__FieldComponent__fieldComponent'] {
        div[class^='jut__FieldComponent__contentContainer'] {
            text-align: right;
        }
    }
}

.policyCommonDetailsContainer {
    margin-top: var(--GW-SPACING-4);

    @include gw-breakpoint-down(tablet) {
        div[class*='fieldComponent']{
            display: flex;
        }
        div[class*='fieldLabelContainer']{
            flex: 2;
        }
        div[class*='contentContainer'] {
            text-align: right;
        }
    }
}

.premiumSeperator {
    border-bottom: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR)
}