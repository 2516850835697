@import "~@jutro/theme/assets/sass/helpers";

.offerLink {
    height: auto;
    width: 100%;
}

.rectangleOfferContainer {
    width: 100%;
    background-color: var(--GW-BRAND-COLOR-1);
    display: flex;
    align-items: center;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .text {
        --gw-personalized-offer-rectangle-text-width: calc(100% - 20%);

        color: var(--GW-BACKGROUND-COLOR);
        max-width: var(--gw-personalized-offer-rectangle-text-width);
    }
}

.leaderBoardOfferContainer {
    --gw-personalize-leader-board-height: 5.6rem;
    height: var(--gw-personalize-leader-board-height);

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .text {
        --gw-personalized-offer-leaderboard-text-width: calc(100% - 20%);

        color: var(--GW-BACKGROUND-COLOR);
        max-width: var(--gw-personalized-offer-leaderboard-text-width);
    }
}