@import "~@jutro/theme/assets/sass/helpers";

.accountSummaryContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
}

.accountSectionReadonly {
    --gw-user-section-readonly-opacity: 0.5;
    opacity: var(--gw-user-section-readonly-opacity);
    @extend .accountSummaryContainer;
}

.accountHeaderContainer {
    margin-bottom: var(--GW-LAYOUT-6);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

h2[class*='accountSummarySectionTitle'] {
    margin-bottom: var(--GW-SPACING-6);
    padding-bottom: var(--GW-SPACING-4);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

h2[class*='billingDetailTitle'] {
    margin-top: var(--GW-SPACING-6);
    padding-bottom: var(--GW-SPACING-4);
}

p[class='noMarginBilling'] {
    margin: 0;
}

.marginTopContainer {
    margin-top: var(--GW-SPACING-4);
}

.globalizationChooserField {
    margin-top: var(--GW-SPACING-3);
}

h3.accountSummaryPageTitle {
    margin: 0;
}

h3.accountSummaryPageSubTitle {
    margin-bottom: var(--GW-SPACING-6);
    margin-top: var(--GW-SPACING-6);
    padding-bottom: var(--GW-SPACING-4);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

h2.accountDetailsEditHeader {
    padding-bottom: var(--GW-SPACING-4);
}

.deliveryMethod :global(.jut__Button__button) {
    padding: unset;
}