.rating {
  --gw-rating-color: var(--GW-COLOR-WARNING);
  --gw-rating-padding-top: var(--GW-LAYOUT-2);
  --gw-star-padding: 0 var(--GW-LAYOUT-1);
  --gw-star-span-padding: 0 1px;

  display: flex;
  flex-flow: row nowrap;
  padding-top: var(--gw-rating-padding-top);
  font-weight: var(--GW-FONT-WEIGHT-BOLD);

  .numericRate {
    color: var(--gw-rating-color);
  }
  .noNumericRate {
    color: var(--GW-SEPARATOR-COLOR);
  }
  .selected {
    .starRate {
      color: var(--GW-SEPARATOR-COLOR);
      .activeStar {
        color: var(--GW-SEPARATOR-COLOR);
      }
    }
  }

  .starRate {
    padding: var(--gw-star-padding);
    .starIcon {
      font-size: var(--GW-ICON-FONT-SIZE-1);
      padding: var(--gw-star-span-padding);
      color: var(--GW-SEPARATOR-COLOR);
    }
    .activeStar {
      color: var(--gw-rating-color);
    }
  }
}