:root {
    --gw-tiles-transition-time: .2s;
    --gw-tiles-active-height: 100%;

    --gw-tiles-header-color-height: 12px;
    --gw-tiles-header-color-hover-height: 12px;

    --gw-tiles-large-width: 200px;
    --gw-tiles-large-height: 22px;

    --gw-tiles-title-min-height: 2.5rem;
    --gw-tiles-height: 104px;

    --gw-tiles-title-top: 8px;
    --gw-tiles-medium-title-height: 3.2rem;

    --gw-active-tile-color-opactiy: 24%;
}

.gwTiles {
    margin: var(--GW-LAYOUT-3) 0;
    a {
        text-decoration: none;
        color: var(--GW-TEXT-COLOR-1);
        transition: color var(--gw-tiles-transition-time);
    }

    .gwTile {
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        min-height: var(--gw-tiles-height);
        position: relative;
        border-radius: var(--GW-BORDER-RADIUS);
        background: var(--GW-BACKGROUND-COLOR);
        box-shadow: var(--GW-SHADOW-2);

        &:hover {
            a {
                color: var(--GW-TEXT-COLOR-2);
            }
        }

        &.gwActive {
            .gwTileColor {
                height: var(--gw-tiles-active-height);
                opacity: var(--gw-active-tile-color-opactiy);
            }
        }
    }
}
.gwTiles.gwTilesLarge {
    padding: 0 var(--GW-LAYOUT-3);

    &:first-child {
        padding-left: 0;
    }

    .gwTile {
        width: var(--gw-tiles-large-width);
        height: var(--gw-tiles-large-width);
        padding: 0 var(--GW-LAYOUT-2);
        &:not(.gwActive):hover {
            .gwTileColor{
                height: var(--gw-tiles-large-height);
            }
        }
    }
    .gwTileColor {
        height: var(--gw-tiles-header-color-height);
    }
    .gwTileContent {
        margin-top: var(--GW-LAYOUT-10);
        margin-bottom: var(--GW-LAYOUT-3);

        & + h4 {
            font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        }

        .tileIcon {
            --gw-tiles-icon-size: 3rem;
            font-size: var(--gw-tiles-icon-size);
        }
    }
}

.gwTiles.gwTilesMedium {
    padding: 0 var(--GW-LAYOUT-1);

    &:last-child {
        padding-right: 0;
    }

    &:first-child {
        padding-left: 0;
    }

    .gwTile {
        --gw-medium-tile-width-height: 132px;

        width: var(--gw-medium-tile-width-height);
        height: var(--gw-medium-tile-width-height);
        border-radius: var(--GW-BORDER-RADIUS);
        padding: 0 var(--GW-LAYOUT-2);

        &:not(.gwActive):hover {
            .gwTileColor{
                height: var(--gw-tiles-header-color-hover-height);
            }
        }
    }
    .gwTileColor {
        height: var(--gw-tiles-title-top);
    }
    .gwTileContent {
        margin-top: var(--GW-LAYOUT-6);

        .tileIcon {
            --gw-tiles-icon-size: 2rem;
            font-size: var(--gw-tiles-icon-size);
        }
    }
}
.gwTileColor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: height var(--gw-tiles-transition-time);
    background-color: var(--GW-ACCENT-COLOR);
}
.gwTileTitle {
    position: relative;
}
.gwTileContent {
    line-height: var(--GW-LINE-HEIGHT-MD);
    position: relative;
}

.linkStyles {
    color: unset;
    text-decoration: unset;
}
