@import "~@jutro/theme/assets/sass/helpers";

:root {
  --gw-tablet-sidebar-width: 12rem;
}

.page {
  --gw-amp-page-width: 80%;
  --gw-amp-page-margin-bottom: 50px;
  --gw-amp-page-padding-top: 40px;
  --gw-amp-page-margin: auto;

  @include gw-breakpoint-only(tablet) {
    --gw-amp-page-width: 95%;
  }

  @include gw-breakpoint-only(phone) {
    --gw-amp-page-width: auto;
    --gw-amp-page-padding-top: 0;
    --gw-amp-page-margin-bottom: 0;
    --gw-amp-page-margin: var(--GW-SPACING-4) 0 var(--GW-SPACING-6);
  }

  width: var(--gw-amp-page-width);
  margin-bottom: var(--gw-amp-page-margin-bottom);
  margin: var(--gw-amp-page-margin);
  padding-top: var(--gw-amp-page-padding-top)
}

body.themeRoot {
  background: var(--GW-BACKGROUND-COLOR-BODY);

 [class*='subApplicationHeader'] {
    background: var(--GW-BACKGROUND-COLOR);
  }
}

// theme
$fonts-root: "../assets/fonts";

// ------------------------------------
//   GROUNDED CSS MODULES
// ------------------------------------
@import "~@jutro/theme/assets/fonts/fonts";
@import "~@jutro/theme/groundedStyles";

.jut__FieldComponent__left .jut__FieldLabel__left {
  flex: 1 1 0;
  align-items: flex-start;
  height: auto;
  text-align: left;

  .jut__FieldLabel__fieldLabel {
    margin-right: var(--GW-SPACING-4);
  }
}

.jut__Accordion__accordionToggle {
  height: auto;
}

.jut__ModalNext__modal {
  --gw-modal-max-height: 90vh;
  > * {
    max-height: var(--gw-modal-max-height);
  }
}

nav .jut__ApplicationHeader__subApplicationNavigation {
  margin-bottom: 0;
}
