.linkSpace {
    padding: var(--GW-SPACING-6);
}
.billingInvoicesContainer {
    overflow: auto;
}

.gwBillingSummaryContainer {
    width: auto;
}

.amountToPayWarningMessage {
    text-align: center;
    color: red;
}
.acceptTnC {
    margin-left: auto;
    margin-top: var(--GW-SPACING-6);
}
.termsCheckbox {
    display: flex;
    align-items: end;
    .link {
        text-decoration: underline;
    }
}
.messageSeparator {
    padding-top: var(--GW-SPACING-2);
}

.convertedWarningMargin {
    margin-bottom: var(--GW-SPACING-4);
}

.wrapWarning {

    div:first-child {
        white-space: normal;
        text-overflow: clip !important;
        overflow: hidden !important;
        min-height: 70px;
    }
}