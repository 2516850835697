.premiumTablePadding {
    padding: 0;
}
.currencyNormal {
    font-weight: normal;
}
.currencyContainer {
    div[class*='PolicyPremiumDetails_currencyNormal'] {
        div[class*='CurrencyField'] {
            font-weight: normal;
        }
    }
}

h2[class='policyPremiumTitle'] {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    margin-bottom: 0;
}