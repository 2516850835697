.totalPremiumContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 var(--GW-LAYOUT-8);

    div {
        flex: 0;
        white-space: nowrap;
    }
}

.policyDriverContainer:not(:last-child) {
    margin-bottom: var(--GW-LAYOUT-10);
}